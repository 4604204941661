<template>
  <Multiselect
    v-model="val"
    :options="options"
    :placeholder="placeholder"
    selectLabel="seleccionar"
    deselectLabel="deseleccionar"
    :loading="loading"
    @search-change="find"
    :internal-search="false"
    @open="openHandler"
    :disabled="disabled"
  >
    <template slot="singleLabel" slot-scope="props">
      {{ props.option.name }}
    </template>
    <template slot="option" slot-scope="{ option }">
      <span>{{ option.name }}</span>
      <div v-show="option.code">
        <span class="badge badge-secondary">{{ option.code }} </span>
      </div>
      <div
        v-show="
          addTreatmentDebts &&
            option.treatment_debts &&
            option.treatment_debts.length > 0
        "
        class="small-text text-danger"
      >
        <b>
          Deudas:
          <app-span-money
            v-for="(l, index) in option.treatment_debts"
            :key="index"
            :quantity="l.debt"
            :moneyCode="l.money_code"
          ></app-span-money>
        </b>
      </div>
    </template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { debounce } from "lodash";
import { StoreService } from "../StoreService";

export default {
  props: {
    addTreatmentDebts: {
      default: false
    },
    placeholder: {
      default: "Selecciona Uno"
    },
    value: {
      default: undefined
    },
    autoSet: {
      default: false
    },
    disabled: {
      default: false
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    }
  },
  data() {
    return {
      loading: false,
      val: undefined,
      options: []
    };
  },
  methods: {
    reset() {
      this.val = null;
      this.$emit("input", null);
    },
    setValue(clientId) {
      this.loading = true;
      StoreService.getClient(clientId).then((res) => {
        this.loading = false;
        this.val = res;
        // this.setValue(res);
        // if (this.value != reg_id) this.$emit("input", reg_id);
      });

      // this.val = val;
      // this.options = [val];
      // if (this.value != val.id) this.$emit("input", val.id);
    },
    setValueFromId(reg_id) {
      this.setValue(reg_id);
    },
    openHandler() {
      if (this.options.length == 0) {
        this.getList();
      }
    },
    getList(search) {
      return new Promise(() => {
        StoreService.getClients({
          search,
          add_treatment_debts: this.addTreatmentDebts ? 1 : undefined
        }).then((res) => {
          this.options = res.data;
          this.loading = false;

          if (this.autoSet && this.options.length == 1) {
            this.val = this.options[0];
            this.$emit("input", this.val.id);
          }
        });
      });
    },
    setDefault() {},
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.loading = true;
      vm.getList(search);
    }, 350)
  }
};
</script>

<style>
.multiselect__placeholder {
  margin-bottom: 0px;
}
</style>
