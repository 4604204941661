<template>
  <div class="input-group">
    <input
      ref="in"
      type="text"
      v-model="code"
      class="form-control"
      placeholder="Codigo"
      @focusout="blinkEvent($event)"
      @keyup.enter="getList()"
    />
  </div>
</template>

<script>
import { StoreService } from "../service";

export default {
  props: {
    value: {}
  },
  data: () => ({
    code: "",
    val: {}
  }),
  methods: {
    focus() {
      this.$refs.in.focus();
    },
    blinkEvent(e) {
      this.$emit("focusout", e);
    },
    reset() {
      this.code = "";
    },
    emitValue() {
      this.$emit("input", this.val.id);
    },
    getList() {
      StoreService.getProducts({
        code: this.code
      }).then((res) => {
        if (res.data.length == 1) {
          this.$emit("product", res.data[0]);
          this.val = res.data[0];
          this.emitValue();
        }
      });
    }
  }
};
</script>

<style></style>
