<template>
  <form @submit.prevent="save()" ref="form">
    <div class="row">
      <div class="form-group col-md-12 col-lg-12">
        <label for>Nombre*</label>
        <input
          required
          type="text"
          class="form-control"
          :disabled="disabled"
          v-model="reg.name"
          @change="emitName()"
          ref="inName"
        />
        <app-small-form-errors :errors="errors.name"></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Documento de Identidad*</label>
        <SelectIdCard
          :disabled="disabled"
          v-model="reg.idcard_type_id"
          required
        ></SelectIdCard>
        <app-small-form-errors
          :errors="errors.idcard_type_id"
        ></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>N° Documento*</label>
        <input
          :disabled="disabled"
          type="text"
          class="form-control"
          v-model="reg.idcard"
          minlength="3"
          required
        />
        <app-small-form-errors :errors="errors.idcard"></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Codigo</label>
        <input
          type="text"
          class="form-control"
          :disabled="disabled"
          v-model="reg.code"
        />
        <app-small-form-errors :errors="errors.code"></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Telefono*</label>
        <input
          required
          type="text"
          class="form-control"
          :disabled="disabled"
          v-model="reg.cell_phone_number"
        />
        <!-- <app-input-phonenumber
            v-model="reg.cell_phone_number"
            :disabled="disabled"
          ></app-input-phonenumber> -->
        <app-small-form-errors
          :errors="errors.cell_phone_number"
        ></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Fecha de Nacimiento*</label>
        <!--<app-datepicker v-model="reg.birthdate" :disabled="disabled"></app-datepicker>-->
        <app-select-date
          v-model="reg.birthdate"
          :disabled="disabled"
        ></app-select-date>
        <app-small-form-errors
          :errors="errors.birthdate"
        ></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Sexo*</label>
        <div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="app_fc_sex_male"
              v-model="reg.sex"
              :disabled="disabled"
              value="1"
              name="sex"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="app_fc_sex_male"
              >Masculino</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="app_fc_sex_female"
              v-model="reg.sex"
              :disabled="disabled"
              value="0"
              name="sex"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="app_fc_sex_female"
              >Femenino</label
            >
          </div>
        </div>
        <app-small-form-errors :errors="errors.sex"></app-small-form-errors>
      </div>

      <!-- <div class="form-group col-md-12 col-lg-4">
          <label for>RUC</label>
          <input
            :disabled="disabled"
            type="text"
            class="form-control"
            v-model="reg.ruc"
            minlength="3"
            maxlength="11"
            required
          />
          <app-small-form-errors :errors="errors.ruc"></app-small-form-errors>
        </div> -->
      <div class="form-group col-md-12 col-lg-4">
        <label for>Dirección</label>
        <input
          :disabled="disabled"
          type="text"
          class="form-control"
          v-model="reg.address"
        />
        <app-small-form-errors :errors="errors.address"></app-small-form-errors>
      </div>
      <div class="form-group col-md-12 col-lg-4">
        <label for>Correo Electronico</label>
        <input
          :disabled="disabled"
          type="email"
          class="form-control"
          v-model="reg.email"
        />
        <app-small-form-errors :errors="errors.email"></app-small-form-errors>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-show="!disabled && !disabledSubmit">
          <app-button-loading
            :loading="sending"
            @click="save()"
            :disabled="errors"
          ></app-button-loading>
        </div>
      </div>
    </div>
    <div class="mb-0 alert alert-danger mt-2" v-show="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
  </form>
</template>

<script>
import SelectIdCard from "./SelectIdCard.vue";
import { StoreService } from "../service";
import * as validate from "validate.js";
import moment from "moment";

const formRules = {
  name: {
    presence: { message: "Se requiere especifiques un nombre" },
    length: { minimum: 5, message: "Minimo de 5 caracteres" }
  },
  cell_phone_number: {
    presence: { message: "Se requiere" },
    length: { minimum: 5, message: "Minimo de 5 caracteres" }
  },
  birthdate: { presence: { message: "Se requiere" } },
  sex: { presence: { message: "Se requiere selecciones uno" } },
  idcard_type_id: { presence: { message: "Se requiere" } },
  idcard: {
    presence: { message: "Se requiere" },
    length: { minimum: 5, message: "Minimo 5 caracteres" }
  }
};

export default {
  components: {
    SelectIdCard
  },
  props: {
    suscribeToName: {
      default: false
    },
    disabled: {
      default: false
    },
    disabledSubmit: {
      default: false
    }
  },
  // mounted() {
  //   console.log(this.$route);
  // },
  data() {
    return {
      reg: {},
      sending: false,
      errorMessage: ""
      // errors: []
    };
  },
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    focusName() {
      this.$refs.inName.focus();
    },
    emitName() {
      if (this.suscribeToName) {
        this.$emit("changeName", this.reg.name);
      }
    },
    getData() {
      return this.$data.reg;
    },
    reset() {
      this.reg = {};
      this.reg.birthdate = moment().format("YYYY/MM/DD");
      this.sending = false;
      this.errorMessage = "";
    },
    load(reg) {
      this.reset();
      if (reg) {
        this.reg = JSON.parse(JSON.stringify(reg));
      }
    },
    loadReg(reg) {
      this.load(reg);
    },
    async save() {
      this.sending = true;
      try {
        let res = await StoreService.saveClient(this.reg);
        this.$emit("submitted", res.id);
        this.errorMessage = "";
      } catch (e) {
        this.errorMessage = e.response.data.message;
      }
      this.sending = false;
    }
  }
};
</script>

<style></style>
