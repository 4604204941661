import http, { getFromStorage } from "../utils/http.js";
import { secureStorage } from "../utils/secureStorage";

export class LogisticService {
  static async getStockReportByBrands(params) {
    return (await http.get("api/logistic/stock/report-by-brands", { params }))
      .data;
  }

  static async deleteRequirementCategorie(id) {
    return (await http.delete(`api/logistic/requirement-categories/${id}`))
      .data;
  }

  static async deleteRequirementDetails(aListIds = []) {
    return (
      await http.post("api/logistic/requirement-details", {
        to_delete: 1,
        ids: aListIds
      })
    ).data;
  }

  static async saveRequirementQuantity(reg) {
    return LogisticService.saveRequirementDetail({
      setQuantitySupplier: 1,
      ...reg
    });
  }

  static async addRequirementSupplier(reg) {
    return LogisticService.saveRequirement({
      add_supplier: 1,
      ...reg
    });
  }

  static async transferOutput(output_id) {
    return (
      await http.put("api/logistic/outputs/" + output_id, {
        transfer: 1
      })
    ).data;
  }

  static async addRequirementCategorie(reg) {
    return (await http.post("api/logistic/requirement-categories", reg)).data;
  }

  static async getRequirementCategories(id) {
    return (
      await http.get("api/logistic/requirement-categories", {
        params: { requirement_id: id }
      })
    ).data;
  }

  static async getSupplier(id) {
    return (await http.get(`api/logistic/suppliers/${id}`)).data;
  }

  static async saveSupplier(reg) {
    if (!reg.id) {
      return (await http.post("api/logistic/suppliers", reg)).data;
    } else {
      return (await http.put("api/logistic/suppliers/" + reg.id, reg)).data;
    }
  }

  static async useStock(reg) {
    return (await http.post("api/logistic/stock/use", reg)).data;
  }

  static async getOutputTimes(params) {
    return (await http.get("api/logistic/reports/output-times", { params }))
      .data;
  }

  static async getRequirements(params) {
    return (await http.get("api/logistic/requirements", { params })).data;
  }

  static async saveRequirementDetail(reg) {
    if (!reg.id) {
      return (await http.post("api/logistic/requirement-details", reg)).data;
    } else {
      return (await http.put("api/logistic/requirement-details/" + reg.id, reg))
        .data;
    }
  }

  static async deleteRequirementDetail(id) {
    return (await http.delete("api/logistic/requirement-details/" + id)).data;
  }

  static async getRequirementDetails(params) {
    return (await http.get("api/logistic/requirement-details", { params }))
      .data;
  }

  static async saveInput(reg) {
    if (!reg.id) {
      return (await http.post("api/logistic/inputs", reg)).data;
    } else {
      return (await http.put(`api/logistic/inputs/${reg.id}`, reg)).data;
    }
  }

  static saveInputDetail = async (reg) => {
    return (await http.post("api/logistic/input-details", reg)).data;
  };

  static saveRequirement = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/logistic/requirements", reg)).data;
    } else {
      return (await http.put("api/logistic/requirements/" + reg.id, reg)).data;
    }
  };

  static getRequirement = async (id) => {
    return (await http.get("api/logistic/requirements/" + id)).data;
  };

  static getSuppliers = async (params) => {
    return (await http.get("api/logistic/suppliers", { params })).data;
  };

  static deleteOutputDetail = async (id) => {
    return (await http.delete("api/logistic/output-details/" + id)).data;
  };

  static getOutput = async (id) => {
    return (await http.get("api/logistic/outputs/" + id)).data;
  };

  static saveStockConfig = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/logistic/stock", reg)).data;
    } else return (await http.put("api/logistic/stock/" + reg.id, reg)).data;
  };

  static getProductStock = async (params) => {
    return (await http.get("api/logistic/product-stock", { params })).data;
  };

  static saveSupplierProduct = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/logistic/supplier-products", reg)).data;
    } else {
      return (await http.put("api/logistic/supplier-products/" + reg.id, reg))
        .data;
    }
  };

  static getSupplierProducts = async (params) => {
    return (await http.get("api/logistic/supplier-products", { params })).data;
  };

  static saveReconfigStock = async (reg) => {
    return (await http.post("api/logistic/stock/reconfig", reg)).data;
  };

  static getStockConfig = async (params) => {
    return (await http.get("api/logistic/stock", { params })).data;
  };

  static addStock = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/logistic/stock/add-stock", reg)).data;
    }
  };

  static saveOutput = async (reg) => {
    if (!reg.id) return (await http.post("api/logistic/outputs", reg)).data;
    else return (await http.put(`api/logistic/outputs/${reg.id}`, reg)).data;
  };

  static deleteInput = async (id) =>
    (await http.delete(`api/logistic/input-details/${id}`)).data;

  static getInput = async (id) => {
    return (await http.get(`api/logistic/inputs/${id}`)).data;
  };

  static async getInputs(params) {
    return (await http.get("api/logistic/inputs", { params })).data;
  }

  static getInputDetails = async (params) => {
    return (await http.get("api/logistic/input-details", { params })).data;
  };

  static getOutputs = async (params) => {
    return (await http.get("api/logistic/outputs", { params })).data;
  };

  static getOutputDetails = async (params) => {
    return (await http.get("api/logistic/output-details", { params })).data;
  };

  static getRucData = async (ruc) => {
    return (await http.get("api/logistic/ruc/" + ruc)).data;
  };

  static saveOutputDetail = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/logistic/output-details", reg)).data;
    }
  };

  // supplier-payments
  static getSupplierPayments = async (params) => {
    return (await http.get("api/logistic/supplier-payments", { params })).data;
  };

  static getSupplierPayment = async (id) => {
    return (await http.get(`api/logistic/supplier-payments/${id}`)).data;
  };

  static saveSupplierPayments = async (reg) => {
    if (reg.id)
      return (await http.put(`api/logistic/supplier-payments/${reg.id}`, reg))
        .data;
    else return (await http.post("api/logistic/supplier-payments", reg)).data;
  };

  static deleteSupplierPayments = async (id) => {
    return (await http.delete(`api/logistic/supplier-payments/${id}`)).data;
  };

  static async getStocks(params) {
    return (await http.get("api/logistic/stock", { params })).data;
  }

  static async getStock(id) {
    return (await http.get(`api/logistic/stock/${id}`)).data;
  }

  static async saveStock(reg) {
    if (reg.id === undefined) {
      return (await http.post("api/logistic/stock", reg)).data;
    } else {
      return (await http.put(`api/logistic/stock/${reg.id}`, reg)).data;
    }
  }

  static async deleteStock(id) {
    return (await http.delete(`api/logistic/stock/${id}`)).data;
  }

  static makeProductTransference(reg) {
    return LogisticService.addStock(reg);
  }

  static async getStores(params) {
    return await getFromStorage("api/logistic/stores", { params });
  }

  static async getStore(id) {
    return await getFromStorage(`api/logistic/stores/${id}`);
  }

  static async saveStore(reg) {
    secureStorage.removeItem("api/logistic/stores");
    if (reg.id === undefined) {
      return (await http.post("api/logistic/stores", reg)).data;
    } else {
      return (await http.put(`api/logistic/stores/${reg.id}`, reg)).data;
    }
  }

  static async deleteStore(id) {
    secureStorage.removeItem("api/logistic/stores");
    return (await http.delete(`api/logistic/stores/${id}`)).data;
  }

  static async getSales(params) {
    return (await http.get("api/logistic/sales", { params })).data;
  }

  static async getSale(id) {
    return (await http.get(`api/logistic/sales/${id}`)).data;
  }

  static async saveSale(reg) {
    if (reg.id === undefined) {
      return (await http.post("api/logistic/sales", reg)).data;
    } else {
      return (await http.put(`api/logistic/sales/${reg.id}`, reg)).data;
    }
  }

  static async deleteSale(id) {
    return (await http.delete(`api/logistic/sales/${id}`)).data;
  }

  static async avoidSale(id, reason) {
    return await LogisticService.saveSale({
      id,
      reason,
      avoid: 1
    });
  }
}
